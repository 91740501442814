// Breadcrumbs.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { BiBuildingHouse } from 'react-icons/bi';

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  const { t } = useTranslation();

  // Check if the first pathname is "home" and remove it if present
  if (pathnames[0] === 'home') {
    pathnames.shift();
  }

  // Define path segments to exclude
  const excludedSegments = ['s', 'p'];

  // Filter out excluded path segments
  const filteredPathnames = pathnames.filter((pathname) => !excludedSegments.includes(pathname));

  // Add a "Home" link represented as "/"
  const breadcrumbs = [
    ...filteredPathnames.map((name, index) => ({
      breadcrumb: t(name),
      match: {
        url: `/${filteredPathnames.slice(0, index + 1).join('/')}`,
      },
    })),
  ];

  return (
    <div className='flex flex-row p-3 items-center border-b'>
      {breadcrumbs.map(({ breadcrumb, match }, index) => {
        const isLast = index === breadcrumbs.length - 1;
        return (
          <div key={match.url} className='flex items-center'>
            {isLast ? (
              // Customize the last breadcrumb (current page)
              <span className="current-page">{breadcrumb}</span>
            ) : (
              // Render the Home link as the first breadcrumb
              index === 0 ? (
                <Link to={match.url} className="breadcrumb-link flex items-center">
                  <BiBuildingHouse className='mr-2' /> {breadcrumb}
                </Link>
              ) : (
                // Render other breadcrumbs
                <Link to={match.url} className="breadcrumb-link">
                  {breadcrumb}
                </Link>
              )
            )}
            {isLast ? null : <span className='mx-2'> - </span>}
          </div>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
