import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import LanguageDetector from 'i18next-browser-languagedetector'; // Import the language detector
import translationsInCzech from "../locales/cs/translation.json";
import translationsInEnglish from "../locales/en/translation.json";

const resources = {
  cs: {
    translation: translationsInCzech,
  },
  en: {
    translation: translationsInEnglish,
  },
};

i18n.use(initReactI18next).init({
  resources,
  debug: true,
  fallbackLng: "cs", // Set the default language
  interpolation: {
    escapeValue: false,
  },
  ns: "translation",
  defaultNS: "translation",
});

export default i18n;
