// react + dependencies
import React from 'react'
import { useTranslation } from 'react-i18next'
// components
import BackToTop from './BackToTop'
import LanguageSelector from './LanguageSelector'
import NavLinkTop from './NavLinkTop'
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';

const Footer = () => {

  const { t, i18n } = useTranslation();

  return (
    <>
    <footer className='w-full bg-[color:var(--sundPr)] mt-20 py-10 text-white'>
      <div className="mx-auto w-full flex flex-col justify-between p-8 lg:p-8 lg:flex-row lg:w-8/12 sm:p-4 sm:w-full">

        {/* FOOTER 1/3 SITEMAP */}
        <div className='w-full my-4 m-auto lg:m-10 sm:my-4 sm:m-auto'>
          <h3 className='mb-6 text-xl font-semibold uppercase'>{t("Footer.Sitemap.Title")}</h3>
            <div className="flex flex-col gap-5 uppercase" id='nav-links'>
            <NavLinkTop
                to={`/${i18n.language}/`}
                className={(navData) => (navData.isActive ? "active" : 'none')}
                onClick={() => window.scrollTo(0, 0)}
              >
                {t("Home")}
              </NavLinkTop>
              <NavLinkTop
                to={`/${i18n.language}/products`}
                className={(navData) => (navData.isActive ? "active" : 'none')}
                onClick={() => window.scrollTo(0, 0)}
              >
                {t("Products.Title")}
              </NavLinkTop>
              <NavLinkTop
                to={`/${i18n.language}/services`}
                className={(navData) => (navData.isActive ? "active" : 'none')}
                onClick={() => window.scrollTo(0, 0)}
              >
                {t("Services.Title")}
              </NavLinkTop>
              <NavLinkTop
                to={`/${i18n.language}/contact`}
                className={(navData) => (navData.isActive ? "active" : 'none')}
                onClick={() => window.scrollTo(0, 0)}
              >
                {t("Contact.Title")}
              </NavLinkTop>
            </div>
        </div>

        {/* FOOTER 2/3 ABOUT */}
        <div className='w-full my-4 m-auto lg:m-10 sm:my-4 sm:m-auto'>
          <h3 className='mb-6 text-xl font-semibold uppercase'>{t("Footer.About.Title")}</h3>
          <p className='font-light'>{t("Footer.About.Text")}</p>
        </div>

        {/* FOOTER 3/3 CONTACT */}
        <div className='w-full mt-2 mb-4 m-auto lg:m-10 sm:my-4 sm:m-auto'>
          <h3 className='mb-6 text-xl font-semibold uppercase'>{t("Footer.Contact.Title")}</h3>
          <p className='font-light'>{t("Footer.Contact.Text")}</p>
          <div className='flex flex-row flex-nowrap gap-6 flex-start my-6'>
            <a href="https://www.facebook.com/profile.php?id=100091257168542" target='_blank' rel='noreferrer'>
              <div className='text-white hover:text-[color:var(--sundOr)] p-3 pl-0 text-xl'>
                < FaFacebook />
              </div>
            </a>
            <a href="https://www.instagram.com/ppezilla/" target='_blank' rel='noreferrer'>
              <div className='text-white hover:text-[color:var(--sundOr)] p-3 text-xl'>
              < FaInstagram />
              </div>
            </a>
            <a href="https://www.linkedin.com/company/ppezilla/" target='_blank' rel='noreferrer'>
              <div className='text-white hover:text-[color:var(--sundOr)] p-3 text-xl'>
              < FaLinkedin />
              </div>
            </a>
          </div>
          <div>
            <h3 className='mb-6 text-xl font-semibold uppercase'>{t("Footer.Language.Title")}</h3>
              <LanguageSelector />
          </div>
        </div>

      </div>
      <BackToTop />
    </footer>
    </>
  )
}

export default Footer
