const serviceData = [
  {
    id: 1,
    title: {
      en: "Trust® by Sundström",
      cs: "Trust® od společnosti Sundström",
    },
    description: {
      en: "Service program to keep your respiratory protection equipment under warranty and to have documented service.",
      cs: "Servisní program pro udržení vašeho respiračního ochranného vybavení v záruce a pro zdokumentovaný servis.",
    },
    perex: {
      en: "The TRUST by Sundström service program is a valuable tool for ensuring that your respiratory protection equipment is kept in good working order and remains under warranty. It is especially important when using the fan units SR 500, SR 700, and SR 500 EX. By servicing your fan units, you can extend the warranty up to 5 years or 5000 running hours, whichever comes first. With TRUST, you will be reminded when it is time for an extended warranty service, and service documents will be saved in the database to keep a record of your units and ensure fully functional respirators. TRUST will send out yearly emails to remind you when it is time for service and maintenance check. It will also keep all your documentation in one place to make it easy and accessible for you to keep track of your products. You can choose your own service partner. We have several authorized service partners that are trained and approved within TRUST and our Sundström products. By selecting an authorized service partner, you will ensure that your fan units get proper maintenance and care, giving you peace of mind and helping to protect your health and safety. TRUST service program is a valuable investment for anyone who uses respiratory protection equipment and wants to keep it in good working order.",
      cs: "Servisní program TRUST by Sundström je cenným nástrojem pro zajištění toho, že vaše vybavení na ochranu dýchacích cest bude udržováno v dobrém provozním stavu a zůstane v záruce. Je to zvláště důležité při použití ventilátorových jednotek SR 500, SR 700 a SR 500 EX. Servisem vašich ventilátorových jednotek můžete prodloužit záruku až na 5 let nebo 5000 provozních hodin, podle toho, co nastane dříve. S TRUST vám bude připomenuto, kdy je čas na prodloužený záruční servis, a servisní dokumenty budou uloženy do databáze, aby bylo možné uchovávat záznamy o vašich jednotkách a zajistit plně funkční respirátory. TRUST bude každoročně rozesílat e-maily, aby vám připomněl, kdy je čas na kontrolu servisu a údržby. Bude také uchovávat veškerou vaši dokumentaci na jednom místě, aby bylo snadné a přístupné sledovat vaše produkty. Můžete si vybrat vlastního servisního partnera. Máme několik autorizovaných servisních partnerů, kteří jsou vyškoleni a schváleni v rámci TRUST a našich produktů Sundström. Výběrem autorizovaného servisního partnera zajistíte řádnou údržbu a péči o vaše ventilátorové jednotky, což vám poskytne klid a pomůže chránit vaše zdraví a bezpečnost. Servisní program TRUST je cennou investicí pro každého, kdo používá prostředky na ochranu dýchacích cest a chce je udržovat v dobrém provozním stavu.",
    },
    url: "trust-sundstrom",
    img: "https://ppezilla.com/img/products/sr_production_051.webp",
  },
  {
    id: 2,
    title: {
      en: "Corrective safety glasses",
      cs: "Korekční brýle",
    },
    description: {
      en: "Corrective safety glasses are a protective tool that combines vision correction with eye protection against risks and hazards.",
      cs: "Korekční ochranné brýle jsou bezpečnostní pomůckou, která kombinuje korekci zraku s ochranou očí před riziky a nebezpečím.",
    },
    perex: {
      en: "Corrective safety glasses represent an innovative combination of corrective optical enhancement and eye protection against various types of risks and hazards. These glasses are designed to provide the wearer not only with optimal vision but also protection against unexpected events such as debris, chemicals, or dust. This is especially valuable in industrial sectors, construction, healthcare, and many other fields where ensuring not only proper vision correction but also eye safety is paramount.",
      cs: "Korekční ochranné brýle představují inovativní kombinaci korekčního optického vylepšení a ochrany očí před různými druhy rizik a nebezpečí. Tyto brýle jsou navrženy tak, aby poskytovaly nositeli nejen optimální vidění, ale také ochranu před nečekanými událostmi, jako jsou úlomky, chemické látky nebo prach. To je zvláště užitečné v průmyslových odvětvích, stavebnictví, lékařství a mnoha dalších oborech, kde je důležité zajistit nejen správnou korekci zraku, ale i bezpečnost očí.",
    },
    url: "corrective-glasses",
    img: "https://ppezilla.com/img/products/sr_image_281.webp",
  },
];

export default serviceData;
