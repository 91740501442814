// react + dependencies
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
// components
import { BiChevronDown } from 'react-icons/bi';

const LanguageSelector = () => {
    const { t, i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    const [showOptions, setShowOptions] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Update the selected language when it changes in i18n
        setSelectedLanguage(i18n.language);
    
        // Retrieve the selected language from the cookie
        const selectedLanguageFromCookie = Cookies.get('selectedLanguage');
        if (selectedLanguageFromCookie) {
            i18n.changeLanguage(selectedLanguageFromCookie);
            setSelectedLanguage(selectedLanguageFromCookie);
        }
        // eslint-disable-next-line
    }, [i18n.language]);
    

    const changeLanguage = (language) => {
        const currentPathname = window.location.pathname;
        const newPathname = currentPathname.replace(/^\/[a-z]{2}/, `/${language}`);
    
        i18n.changeLanguage(language);
    
        // Store the selected language in a cookie
        Cookies.set('selectedLanguage', language, { expires: 365 });
    
        setSelectedLanguage(language);
        setShowOptions(false);
        navigate(newPathname);
    
        window.scrollTo(0, 0);
    };
    

    // Check if the selectedLanguage is a supported language, otherwise, use a universal flag
    const flagSrc = supportedLanguages.includes(selectedLanguage)
        ? `https://ppezilla.com/img/flags/select-flag-${selectedLanguage}.png`
        : 'https://ppezilla.com/img/flags/select-flag-uni.png';

    return (
        <div className="language-selector">
            <div className='flex flex-row gap-5 items-center w-fit text-center cursor-pointer' onClick={() => setShowOptions(!showOptions)}>
                <div className='flex'>
                    <button
                        className={`selected-language ${showOptions ? 'active' : ''}`}
                    >
                        <img
                            src={flagSrc}
                            alt={selectedLanguage}
                            className="image-option w-8"
                        />
                    </button>
                </div>
                <div>
                    <BiChevronDown
                        className={`chevron-icon ${showOptions ? 'rotate' : ''}`}
                    />
                </div>
            </div>
            <div className={`language-options ${showOptions ? 'open' : ''}`}>
                <div className='flex flex-col mt-4 divide-y w-fit'>
                    <button
                        className={`language-button ${selectedLanguage === 'cs' ? 'selected' : ''}`}
                        onClick={() => changeLanguage('cs')}
                    >
                        <div className='flex flex-row gap-5 py-4 bg-transparent hover:bg-slate-500/50'>
                            <img src={`https://ppezilla.com/img/flags/select-flag-cs.png`} alt="CZ" className="image-option w-8" />
                            <p className='capitalize text-sm'>{t("Language.Selector.CS")}</p>
                        </div>
                    </button>
                    <button
                        className={`language-button ${selectedLanguage === 'en' ? 'selected' : ''}`}
                        onClick={() => changeLanguage('en')}
                    >
                        <div className='flex flex-row gap-5 py-4 bg-transparent hover:bg-slate-500/50'>
                            <img src={`https://ppezilla.com/img/flags/select-flag-en.png`} alt="EN" className="image-option w-8" />
                            <p className='capitalize text-sm'>{t("Language.Selector.EN")}</p>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
};

const supportedLanguages = ['cs', 'en']; // Add supported languages here

export default LanguageSelector;
