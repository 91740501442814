// react + dependencies
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
// api
import productData from '../api/productList';
// components
import PageTitle from '../components/PageTitle';
import NavLinkTop from '../components/NavLinkTop';


const Products = () => {

    const { t, i18n } = useTranslation();
    const activeLanguage = i18n.language;

    const [searchInput, setSearchInput] = useState('');
    const [filteredProducts, setFilteredProducts] = useState(productData);

    const handleSearchInputChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchInput(searchTerm);

    // Filter products based on title and description
    const filtered = productData.filter((product) => {
        const title = t(product.title[activeLanguage]).toLowerCase();
        const description = t(product.description[activeLanguage]).toLowerCase();
        return title.includes(searchTerm) || description.includes(searchTerm);
    });

    setFilteredProducts(filtered);
    };

    useEffect(() => {
        // Trigger Product Detail View event when the component mounts
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageVisit',
                pageName: 'Products',
                localization: activeLanguage
            },
        });
      }, [activeLanguage, t]); // Include t in the dependency array
    

return (
    <>
    <PageTitle title={t("Products.Title")} />
    <div className='w-full'>
        <div className='relative bg-[url("../img/products/sr_safety_073.webp")] bg-center bg-no-repeat bg-cover border-b-4 border-[color:var(--sundOr)]'>
        <div className='absolute inset-0 bg-black opacity-60'></div>
            <div className='w-full md:w-9/12 m-auto '>
                <div className='min-h-[40vh] md:min-h-[60vh] p-8 flex items-center justify-between text-white relative text-start md:p-0'>
                    <div className='m-auto pt-[80px] ml-0'>
                        <h1 className='mb-4 md:mb-6'>{t("Products.Title")}</h1>
                        <p>{t("Products.Perex")}</p>
                    </div>
                </div>
            </div>
        </div>
        <div className='w-full md:w-9/12 m-auto'>
            <div className='w-full md:w-96 py-12 px-6 md:px-0'>
                <input
                    type='text'
                    placeholder={t('Product.Search')}
                    className='w-full shadow rounded py-2 px-3 focus-visible:shadow-lg focus-visible:outline-none'
                    value={searchInput}
                    onChange={handleSearchInputChange}
                />
            </div>
            <div className='flex flex-col md:flex-row gap-8 justify-start flex-wrap p-8 md:p-0'>
            {filteredProducts.map((product) => (
                <NavLinkTop to={`/${i18n.language}/products/${product.url}`} key={product.id}>
                    <div className='min-h-32 md:min-h-96 min-w-32 md:w-96 p-10 shadow hover:shadow-xl border'>
                        <div className='mb-6'>
                            <img src={product.mainImg} width={200} alt={t(product.title[activeLanguage])} />
                        </div>
                        <div>
                            <p className='font-semibold text-lg mb-4'>{t(product.title[activeLanguage])}</p>
                            <p className='text-sm leading-6'>{t(product.description[activeLanguage])}</p>
                        </div>
                    </div>
                </NavLinkTop>
            ))}
            </div>
        </div>
    </div>
    </>
    )
}

export default Products;