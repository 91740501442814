const productData = [
    {
        id: 1,
        title: {
            en: 'SR 200 PC',
            cs: 'SR 200 PC',
        },
        description: {
            en: 'Full face mask with excellent fit, high protection level, eye protection and very good breathing comfort for use in the toughest work environments.',
            cs: 'Celoobličejová maska s vynikajícím padnutím, vysokou úrovní ochrany, ochranou očí a velmi dobrým komfortem dýchání pro použití v nejnáročnějších pracovních prostředích.',
        },
        url: 'sr-200-pc',
        mainImg: 'https://srsafety.com/_next/image?url=https%3A%2F%2Fadmin.srsafety.com%2Fwp-content%2Fuploads%2F2023%2F04%2FH01-2012_001.jpg&w=1920&q=75',
        productImages: [
            'https://srsafety.com/_next/image?url=https%3A%2F%2Fadmin.srsafety.com%2Fwp-content%2Fuploads%2F2023%2F04%2FH01-2012_001.jpg&w=1920&q=75',
            'https://srsafety.com/_next/image?url=https%3A%2F%2Fadmin.srsafety.com%2Fwp-content%2Fuploads%2F2023%2F04%2FH01-2012_002-1.jpg&w=1920&q=75',
            'https://srsafety.com/_next/image?url=https%3A%2F%2Fadmin.srsafety.com%2Fwp-content%2Fuploads%2F2023%2F04%2FH01-2012_007-1.jpg&w=1920&q=75',
        ],
    },
    {
        id: 2,
        title: {
            en: 'SR 520 S/M',
            cs: 'SR 520 S/M',
        },
        description: {
            en: 'The hood together with the battery-powered SR 500, SR 500 EX or SR 700 fan and approved filters are included in the Sundström fan-assisted respiratory protection device systems. The breathing hose of the hood is connected to the fan, equipped with a filter. The pressure generated in the hood prevents particles and other pollutants from entering the hood. The hoods can also be used with the SR 507 compressed air equipment. This combination forms a breathing apparatus designed for continuous air flow, for connection to a compressed air supply. The equipment can be used as an alternative to filter respirators in all situations for which filter respirators are recommended. This applies particularly to work that is hard, warm or of long duration.',
            cs: 'Maska spolu s bateriovým ventilátorem SR 500, SR 500 EX nebo SR 700 a schválenými filtry jsou součástí systémů ochrany dýchacích orgánů Sundström s ventilátorem. Dýchací hadice kukly je napojena na ventilátor, vybavený filtrem. Tlak generovaný v digestoři zabraňuje vnikání částic a jiných znečišťujících látek do digestoře. Digestoře lze také použít se zařízením na stlačený vzduch SR 507. Tato kombinace tvoří dýchací přístroj určený pro nepřetržité proudění vzduchu, pro připojení na přívod stlačeného vzduchu. Zařízení lze použít jako alternativu k filtračním respirátorům ve všech situacích, pro které jsou filtrační respirátory doporučeny. To platí zejména pro práci, která je těžká, teplá nebo dlouhodobá.',
        },
        url: 'sr-520-s-m',
        mainImg: 'https://srsafety.com/_next/image?url=https%3A%2F%2Fadmin.srsafety.com%2Fwp-content%2Fuploads%2F2023%2F03%2FH06-0312_001.jpg&w=1920&q=75',
        productImages: [
            'https://srsafety.com/_next/image?url=https%3A%2F%2Fadmin.srsafety.com%2Fwp-content%2Fuploads%2F2023%2F03%2FH06-0312_001.jpg&w=1920&q=75',
            'https://srsafety.com/_next/image?url=https%3A%2F%2Fadmin.srsafety.com%2Fwp-content%2Fuploads%2F2023%2F03%2FH06-0312_008.jpg&w=1920&q=75',
            'https://srsafety.com/_next/image?url=https%3A%2F%2Fadmin.srsafety.com%2Fwp-content%2Fuploads%2F2023%2F04%2FH06-0312_004-1.jpg&w=1920&q=75',
        ],
    },
];

export default productData;