// react + dependencies
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
// api
import serviceData from '../api/serviceList';
// components
import PageTitle from './PageTitle';
import NavLinkTop from './NavLinkTop';
import Breadcrumbs from './Breadcrumbs';

const ServiceDetail = () => {
    const { id } = useParams(); // Get the 'id' parameter from the route
    const { t, i18n } = useTranslation();
    const activeLanguage = i18n.language;

    // Replace this with your actual logic to fetch product details
    const service = serviceData.find((service) => service.url === id);

    useEffect(() => {
      // Trigger Product Detail View event when the component mounts
      TagManager.dataLayer({
          dataLayer: {
              event: 'serviceDetailView',
              serviceId: service.id, // Pass the unique service ID
              serviceName: t(service.title[activeLanguage]), // Pass the service name translated to the current language
              localization: activeLanguage
          },
      });
    }, [service, activeLanguage, t]); // Include t in the dependency array


  return (
    <>
      <PageTitle title={t(service.title[activeLanguage])} />
      <div className="w-full">
        <div className="w-full md:w-9/12 m-auto ">
          <div className="h-full md:min-h-[40vh] p-8 flex flex-col md:flex-row items-center justify-start text-start md:p-0">
            <div className="m-auto pt-[80px] ml-0 flex flex-col md:flex-row items-center"> 
              <div className='w-full md:w-3/12'>
                <img src={service.img} alt={service.title} className='rounded shadow' />
              </div>
              <div className='w-full md:w-10/12 p-0 md:p-10'>
                <div className='mb-6 uppercase' id='breadcrumbs'>
                  <Breadcrumbs />
                </div>
                <h1 className="mb-4 md:mb-6">{t(service.title[activeLanguage])}</h1>
                <p className='font-semibold'>{t(service.description[activeLanguage])}</p>
              </div>           
            </div>
          </div>
          <div className='p-8 md:p-0'>
            <p>{t(service.perex[activeLanguage])}</p>
              <NavLinkTop to={`/${i18n.language}/contact/?selectedService=${service.url}`}>
                <button className='btn-primary'>
                  {t("Services.Service.Detail.Contact.Button")}
                </button>
              </NavLinkTop>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceDetail;
