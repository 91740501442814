// react + dependencies
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// components
import PageTitle from '../components/PageTitle';
import NavLinkTop from '../components/NavLinkTop';
import serviceData from '../api/serviceList';
import TagManager from 'react-gtm-module';

const Services = () => {
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;

  const navigate = useNavigate();
  const handleServiceClick = (service) => {
    navigate(`/${i18n.language}/p-2/s/${service.url}?selectedService=${service.url}`);
  };

  useEffect(() => {
    // Trigger Product Detail View event when the component mounts
    TagManager.dataLayer({
        dataLayer: {
            event: 'pageVisit',
            pageName: 'Services',
            localization: activeLanguage
        },
    });
  }, [activeLanguage, t]); // Include t in the dependency array


  return (
    <>
      <PageTitle title={t("Services.Title")} />
      <div className='w-full'>
        <div className='relative bg-[url("../img/products/sr_image_281.webp")] bg-center bg-no-repeat bg-cover border-b-4 border-[color:var(--sundOr)]'>
          <div className='absolute inset-0 bg-black opacity-60'></div> 
          <div className='w-full md:w-9/12 m-auto '>
            <div className='min-h-[40vh] md:min-h-[60vh] p-8 flex items-center justify-start text-start text-white md:p-0 relative '>
              <div className='m-auto pt-[80px] ml-0'>
                <h1 className='mb-4 md:mb-6'>{t("Services.Title")}</h1>
                <p>{t("Services.Perex")}</p>
              </div>
            </div>
          </div>
        </div>

        <div className='w-full md:w-9/12 m-auto py-12'>
          <div className='flex flex-col md:flex-row gap-8 justify-start flex-wrap p-8 md:p-0'>
            {serviceData.map((service) => (
              <NavLinkTop to={`/${i18n.language}/services/${service.url}`} key={service.id}>
                <div className='min-h-32 md:min-h-96 min-w-32 md:w-96 shadow hover:shadow-xl border'>
                  <div className='mb-6'>
                    <img src={service.img} alt={t(service.title[activeLanguage])} className='rounded-b-none rounded' />
                  </div>
                  <div className='p-8'>
                    <p className='font-semibold text-lg mb-4'>{t(service.title[activeLanguage])}</p>
                    <p className='text-sm leading-6'>{t(service.description[activeLanguage])}</p>
                    <div>
                      <button className='btn-primary' onClick={() => handleServiceClick(service)}>
                        {t("Services.Service.Detail.Button")}
                      </button>
                    </div>
                  </div>
                </div>
              </NavLinkTop>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;