// react + dependencies
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom'; // Import useLocation from react-router-dom
import TagManager from 'react-gtm-module';
// api
import productList from '../api/productList';
import serviceList from '../api/serviceList';
// components
import NavLinkTop from './NavLinkTop'

const ContactForm = ({ serviceTitles }) => {
    const { t, i18n } = useTranslation();
    const [selectedService, setSelectedService] = useState('');
    const [selectedProduct, setSelectedProduct] = useState(''); // Initialize with an empty string
    const [showProductDropdown, setShowProductDropdown] = useState(false);
    const location = useLocation(); // Get the current location
    const [formSubmitted, setFormSubmitted] = useState(false); // State to track form submission
    const [loading, setLoading] = useState(false);


    const handleServiceChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedService(selectedValue);
        // Check if the selected value is "Contact.Form.Product.Samples" and update selectedProduct accordingly
        setSelectedProduct(selectedValue === 'samples' ? 'samples' : '');
        setShowProductDropdown(selectedValue === 'samples');
    };
    

    useEffect(() => {
        // Parse the query parameter to get the selected service and product
        const searchParams = new URLSearchParams(location.search);
        const serviceQueryParam = searchParams.get('selectedService');
        const productQueryParam = searchParams.get('selectedProduct');
    
        if (serviceQueryParam === 'samples') {
            setSelectedService('samples');
            setShowProductDropdown(true);
        } else if (serviceQueryParam) {
            setSelectedService(serviceQueryParam);
            setShowProductDropdown(serviceQueryParam === 'samples');
        }
    
        if (productQueryParam) {
            setSelectedProduct(productQueryParam);
            setShowProductDropdown(true);
        }
        
    }, [location.search]);
        
    const handleProductChange = (e) => {
        setSelectedProduct(e.target.value);
    };

    useEffect(() => {
        if (formSubmitted) {
            window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page smoothly
        }
    }, [formSubmitted]); // Run this effect when formSubmitted state changes

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true when form submission starts

        const formData = new FormData(e.target);    
        try {
            const response = await fetch('https://app.headlessforms.cloud/api/v1/form-submission/i2rsysh9Yf', {
                method: 'POST',
                body: formData,
            });
    
            if (response.ok) {
                // Form submitted successfully
                setFormSubmitted(true); // Set formSubmitted state to true
    
                // Extract form data from the form fields
                const name = formData.get('name');
                const email = formData.get('email');
                const phone = formData.get('phone');
                const message = formData.get('message');
                const serviceSelect = formData.get('serviceSelect');
                const productSelect = formData.get('productSelect');
    
                // Send data to GTM dataLayer
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'formSubmission',
                        formType: 'contactForm',
                        formData: {
                            name: name,
                            email: email,
                            phone: phone,
                            message: message,
                            service: serviceSelect,
                            product: productSelect,
                        },
                    },
                });
            } else {
                // Handle form submission error (optional)
            }
        } catch (error) {
            // Handle network or other errors during form submission (optional)

        } finally {
            setLoading(false); // Reset loading to false after form submission is complete
        }
    };

    return (
        <div>
            {!formSubmitted ? (
                <form 
                    onSubmit={handleFormSubmit} 
                    encType="multipart/form-data" 
                    className='flex flex-col'
                >

                    {/* Dropdown for selecting a service */}
                    <label htmlFor="serviceSelect">
                        {t("Contact.Form.ServiceLabel")}:
                    </label>
                    <select
                        id="serviceSelect"
                        name="serviceSelect"
                        value={selectedService} // Set the value to selectedService
                        onChange={handleServiceChange}
                        required
                    >
                        <option value="" disabled>
                            {t("Contact.Form.ServicePlaceholder")}
                        </option>
                        <option value="informations">
                            {t("Contact.Form.Service.IDK")}
                        </option>
                        {/* SKRYTO 23.10. NA PETRŮV POŽADAVEK 
                        <option value="samples">
                            {t("Contact.Form.Product.Samples")}
                        </option>
                        */}
                        {serviceList.map((service) => (
                            <option key={service.id} value={service.url}>
                                {t(service.title[i18n.language])}
                            </option>
                        ))}
                    </select>

                    {/* SKRYTO 23.10. NA PETRŮV POŽADAVEK */}
                    {/* Dropdown for selecting a product (conditionally rendered) 
                    {showProductDropdown && (
                        <div className='grid'>
                            <label htmlFor="productSelect" className='mt-4'>
                                {t("Contact.Form.ProductLabel")}
                            </label>
                            <select
                                id="productSelect"
                                name="productSelect"
                                value={selectedProduct} // Set the value to selectedProduct
                                onChange={handleProductChange}
                                required
                                >
                                <option value="" disabled>
                                    {t("Contact.Form.ProductPlaceholder")}
                                </option>
                                {productList.map((product) => (
                                    <option key={product.id} value={product.url}> 
                                    {t(product.title[i18n.language])}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                    */}


                    {/* Existing form fields */}
                    <label htmlFor="name" className='mt-4'>
                        {t("Contact.Form.Name")}:
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Jan Novák"
                        maxLength="null"
                        minLength="null"
                        required="required"
                    />

                    <label htmlFor="phone" className='mt-4'>
                        {t("Contact.Form.Phone")}:
                    </label>
                    <input
                        type="phone"
                        id="phone"
                        name="phone"
                        placeholder="+420 123 456 789"
                        maxLength="null"
                        minLength="9"
                        required="required"
                    />

                    <label htmlFor="email" className='mt-4'>
                        {t("Contact.Form.Email")}:
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="info@email.com"
                        maxLength="null"
                        minLength="null"
                        required="required"
                    />

                    <label htmlFor="textarea" className='mt-4'>
                        {t("Contact.Form.Text")}:
                    </label>
                    <textarea
                        type="message"
                        id="message"
                        name="message"
                        placeholder={t("Contact.Form.Text.Placeholder")}
                        maxLength="null"
                        minLength="null"
                        required="required"
                        rows={5}
                    />

                    <button type="submit" className='mt-14 btn-primary uppercase py-5 w-full md:w-48 m-auto animate-bounce'>
                        {loading ? <div className="loading-spinner"></div> : t("Contact.Form.Submit")}
                    </button>
                </form>
            ) : (
                <div className="text-center">
                    <h2>{t("Contact.Form.ThankYouMessage")}</h2>
                    <NavLinkTop to='/'>
                        <button className='btn-primary'>
                            {t("Button.BackToHome")}
                        </button>
                    </NavLinkTop>
                </div>
            )}
        </div>
    );
}

export default ContactForm;
