// react + dependencies
import React, { useEffect } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import TagManager from 'react-gtm-module'
// components
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import ServiceDetail from "./components/ServiceDetail";
import ProductDetail from "./components/ProductDetail";
import CookieBanner from "./components/CookieBanner";
// pages
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Products from "./pages/Products";

  // gtm tracking script starts
  const tagManagerArgs = {
    gtmId: 'GTM-MF9RXMK5',
    events: {
      pageView: 'Page View', // Track page views
      languageChange: 'Language Change', // Track language changes
      productDetailView: 'Product Detail View', // Track when a product detail page is viewed
      serviceDetailView: 'Service Detail View', // Track when a service detail page is viewed
      contactFormSubmit: 'Contact Form Submit', // Track contact form submissions
      navLinkClick: 'Navigation Link Click', // Track clicks on navigation links
      search: 'Search', // Track search queries
      scroll: 'Scroll', // Track user scroll behavior
    },
  }
  TagManager.initialize(tagManagerArgs)
  // gtm tracking script ends

function App() {

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check if the URL has a localization prefix (/cs or /en)
    const currentPathname = location.pathname;
    const supportedLanguages = ["cs", "en"]; // Add other supported languages if needed

    if (supportedLanguages.includes(currentPathname.split("/")[1])) {
      // Language prefix found in the URL
      const languageFromURL = currentPathname.split("/")[1];

      if (languageFromURL !== i18n.language) {
        // Change the language if it's different from the current language
        i18n.changeLanguage(languageFromURL);
      }
    } else {
      // Language prefix not found in the URL
      // Redirect to the default language
      const defaultLanguage = i18n.language;
      navigate(`/${defaultLanguage}`);
    }
  }, [navigate, location]);

  return (
    <>
      <I18nextProvider i18n={i18n}>
        <Navbar />
        {/*<CookieBanner />*/}
        <Routes>
          <Route path="/:lang" element={<Home />} />
          <Route path="/:lang/products" element={<Products />} />
          <Route path="/:lang/products/:id" element={<ProductDetail />} />
          <Route path="/:lang/services/" element={<Services />} />
          <Route path="/:lang/services/:id" element={<ServiceDetail />} />
          <Route path="/:lang/contact/" element={<Contact />} />
          <Route path="/*" element={<Home />} /> {/* Default route to Home */}
        </Routes>
        <Footer />
      </I18nextProvider>
    </>
  );
}

export default App;
