import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';

const ScrollToTopNavLink = ({ to, children }) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div onClick={scrollToTop}>
      <RouterNavLink to={to}>{children}</RouterNavLink>
    </div>
  );
};

export default ScrollToTopNavLink;
