// react + dependencies
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
// api
import serviceData from '../api/serviceList';
// components
import PageTitle from '../components/PageTitle';
import ContactForm from '../components/ContactForm';

const Contact = () => {
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;


  // Extract service titles from serviceData
  const serviceTitles = serviceData.map((service) => service.title);

  useEffect(() => {
    // Trigger Product Detail View event when the component mounts
    TagManager.dataLayer({
        dataLayer: {
            event: 'pageVisit',
            pageName: 'Contact',
            localization: activeLanguage
        },
    });
  }, [activeLanguage, t]); // Include t in the dependency array

  return (
    <>
      <PageTitle title={t("Contact.Title")} />
      <div className='w-full'>
        <div className='relative bg-[url("../img/products/sr_production_201.webp")] bg-center bg-no-repeat bg-cover border-b-4 border-[color:var(--sundOr)]'>
        <div className='absolute inset-0 bg-black opacity-60'></div> 
          <div className='w-full md:w-9/12 m-auto '>
            <div className='min-h-[40vh] md:min-h-[60vh] p-8 flex items-center justify-start text-start text-white md:p-0 relative '>
              <div className='m-auto pt-[80px] ml-0'>
                <h1 className='mb-4 md:mb-6'>{t("Contact.Title")}</h1>
                <p>{t("Contact.Perex")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className='w-full md:w-8/12 m-auto p-8'>
          <ContactForm serviceTitles={serviceTitles} />
        </div>
      </div>
    </>
  );
}

export default Contact;
