import React, { useState, useEffect } from 'react';
import { FiArrowUp } from 'react-icons/fi'; // Import the arrow icon from react-icons

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setIsVisible(scrollTop > 100);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div
      className={`fixed bottom-6 right-6 z-50 bg-white hover:bg-[color:var(--sundOr)] rounded-full p-2 cursor-pointer ${
        isVisible ? 'opacity-100 cursor-pointer' : 'opacity-0 cursor-default'
      } transition-opacity duration-500`}
      onClick={isVisible ? scrollToTop : null}
    >
      <FiArrowUp className="text-black text-xl" />
    </div>
  );
};

export default BackToTop;
