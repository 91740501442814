// react + dependencies
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
// api
import productData from '../api/productList';
// components
import PageTitle from './PageTitle';
import EmblaCarousel from './EmblaCarousel';
import NavLinkTop from './NavLinkTop';
import Breadcrumbs from './Breadcrumbs';

const ProductDetail = () => {
    const { id } = useParams();
    const { t, i18n } = useTranslation();
    const activeLanguage = i18n.language;

    const product = productData.find((product) => product.url === id);

    // Access the product images from the API
    const productImages = product.productImages;

    const OPTIONS = {};
    const SLIDE_COUNT = 10;
    const SLIDES = Array.from(Array(SLIDE_COUNT).keys());

    useEffect(() => {
        // Trigger Product Detail View event when the component mounts
        TagManager.dataLayer({
            dataLayer: {
                event: 'productDetailView',
                productId: product.id, // Pass the unique product ID
                productName: t(product.title[activeLanguage]), // Pass the product name translated to the current language
                localization: activeLanguage
            },
        });
    }, [product, activeLanguage, t]); // Include t in the dependency array

return (
    <>
    <PageTitle title={t(product.title[activeLanguage])} />
    <div className="w-full" id='wrap'>
        <div className="w-full md:w-9/12 m-auto pt-[80px]">
            <div className='uppercase' id='breadcrumbs'>
                <Breadcrumbs />
            </div>
            <div className="h-full flex flex-col md:flex-row items-start justify-between text-black text-start md:p-0 ">
                {/* 1/2 Left section - image */}
                <div className="w-full md:w-6/12 p-6">
                    <div>
                    {/* Pass the images as props to EmblaCarousel */}
                        <EmblaCarousel slides={SLIDES} options={OPTIONS} images={productImages} />
                    </div>
                </div>
                {/* 2/2 Right section - product information */}
                <div className="w-full md:w-6/12 p-6 md:p-20 md:pt-10">
                    <h1 className="mb-4 md:mb-6">{t(product.title[activeLanguage])}</h1>
                    <p>{t(product.description[activeLanguage])}</p>
                    {/* SKRÝVÁM DOKUD NEBUDE POUŽITELNÝ DOMYŠLENÝ CONTENT
                    <div className="w-full mt-4">
                        {/* Image gallery */}
                        {/* SKRÝVÁM DOKUD NEBUDE POUŽITELNÝ DOMYŠLENÝ CONTENT
                        <div className="w-full flex flex-row gap-5 justify-start my-10">
                            {productImages.map((imageUrl, index) => (
                            <div key={index}>
                                <img src={imageUrl} alt={t(product.title[activeLanguage])} width={100} />
                            </div>
                        ))}
                        </div>
                        {/* Product description */}
                        {/* SKRÝVÁM DOKUD NEBUDE POUŽITELNÝ DOMYŠLENÝ CONTENT
                        <div>
                            <ul>
                                <li>Very high protection factor (NPF 2000)</li>
                                <li>Two exhalation valves</li>
                                <li>Valve caps with air diffusion (no clogging during spray jobs)</li>
                            </ul>
                        </div>
                        {/* SKRYTO 23.10. NA PETRŮV POŽADAVEK 
                        <div>
                            <NavLinkTop to={`/${i18n.language}/contact/?selectedService=samples&selectedProduct=${product.url}`}>
                                <button className='btn-primary'>
                                    {t("Product.Detail.Button")}
                                </button>
                            </NavLinkTop>
                        </div>
                    </div>
                    */}
                </div>
            </div>
            <div className="my-6 p-8 md:p-12">
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Necessitatibus dignissimos ipsa minima a facere nemo numquam sit consectetur accusamus rerum expedita debitis molestias harum deleniti possimus eum magni nihil ab maiores, iste, magnam eius quaerat modi. Recusandae natus voluptate culpa voluptates at! Asperiores veniam facere, explicabo repellendus magnam praesentium, quae non itaque magni laboriosam modi quaerat porro commodi quam incidunt deserunt a eum! Unde illo labore blanditiis cupiditate iste sequi, itaque voluptatum assumenda voluptate numquam laboriosam ipsa cum eveniet reiciendis. Sunt blanditiis consectetur repudiandae voluptate iste fuga reiciendis quos voluptatum recusandae, dolor sit debitis aperiam beatae alias dolore ratione at.</p>
            </div>
            <div className="my-6">
                <div className='flex flex-col md:flex-row gap-3'>
                    <div className='w-full md:w-6/12'>
                        <img src="https://ppezilla.com/img/products/sr_image_281.webp" alt={t(product.title[activeLanguage])} />
                    </div>
                    <div className='w-full md:w-6/12'>
                        <img src="https://ppezilla.com/img/products/sr_safety_073.webp" alt={t(product.title[activeLanguage])} />
                    </div>
                </div>
            </div>
            <div className="my-6 p-8 md:p-12">
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laboriosam quaerat odio placeat alias nemo incidunt expedita quibusdam amet debitis culpa neque blanditiis officia autem veritatis dolorem repellendus ipsa eos vel quo dignissimos minima, voluptates, excepturi harum! Ipsam unde alias officiis laboriosam! Provident, ad officia? Reiciendis aspernatur eum repellat debitis fugiat!</p>
            </div>
        </div>
    </div>
    </>


    );
};

export default ProductDetail;
