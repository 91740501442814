import { useEffect } from 'react';

const PageTitle = ({ title }) => {
  const siteName = 'PPE Zilla'; // Replace 'My Site' with your actual site name

  useEffect(() => {
    document.title = `${title} - ${siteName}`; // Update the document title with the site name and page name
    return () => {
      document.title = 'PPE Zilla'; // Reset the document title when the component unmounts
    };
  }, [siteName, title]);

  return null; // The component doesn't need to render anything
};

export default PageTitle;
