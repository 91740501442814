import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import LanguageSelector from './LanguageSelector';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  // Mobile menu toggler
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Back to top
  const backTop = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    } 
    window.scrollTo(0, 0);
  };

  // Check if the provided language is supported
  const isLanguageSupported = (language) => {
    const supportedLanguages = ['cs', 'en']; // Add other supported languages if needed
    return supportedLanguages.includes(language);
  };

  // Redirect to the default language if the language is not supported
  const redirectToDefaultLanguage = () => {
    navigate('/cs'); // Default language is "cs"
  };

  // Ensure the language is supported or redirect
  useEffect(() => {
    if (!isLanguageSupported(i18n.language)) {
      redirectToDefaultLanguage();
    }
    // eslint-disable-next-line
  }, [i18n.language]);

  // Remove any additional localization information from the URL
  useEffect(() => {
    const currentPathname = window.location.pathname;
    const languagePrefix = `/${i18n.language}`;
    
    if (currentPathname.startsWith(languagePrefix)) {
      const newPathname = currentPathname.replace(languagePrefix, '');
      navigate(newPathname);
    }
    // eslint-disable-next-line
  }, [i18n.language]);

  return (
    <>
      {/* Overlay */}
      {isMenuOpen && <div className="overlay" onClick={handleMenuToggle}></div>}

      {/* Rest of your navbar component */}
      <nav id="navbar" className="z-50 w-full shadow-lg fixed top-0 bg-gray-900/75 transition-all duration-300 ease-in-out h-[80px]">
        <div className="h-full px-4 lg:px-8 sm:px-6">
          <div className="h-full flex justify-between items-center">
            <div>
              <a href={`/${i18n.language}/`} className="text-lg text-white">
                <img src="https://ppezilla.com/img/logo.png" className='hover:scale-90 transition-all shadow-none' width={200} alt="logo" />
              </a>
            </div>

            {/* Desktop menu */}
            <div className="hidden md:flex">
              <div className="flex items-center flex-end gap-5 text-white uppercase" id='nav-links'>
                <NavLink
                  to={`/${i18n.language}/`}
                  className={(navData) => (navData.isActive ? "active" : 'none')}
                  onClick={backTop}
                >
                  {t("Home")}
                </NavLink>
                <NavLink
                  to={`/${i18n.language}/products/`}
                  className={(navData) => (navData.isActive ? "active" : 'none')}
                  onClick={backTop}
                >
                  {t("Products.Title")}
                </NavLink>
                <NavLink
                  to={`/${i18n.language}/services/`}
                  className={(navData) => (navData.isActive ? "active" : 'none')}
                  onClick={backTop}
                >
                  {t("Services.Title")}
                </NavLink>
                <NavLink
                  to={`/${i18n.language}/contact/`}
                  className={(navData) => (navData.isActive ? "active" : 'none')}
                  onClick={backTop}
                >
                  <button className='btn-primary uppercase mt-0'>
                    {t("Contact.Title")}
                  </button>
                </NavLink>
                <LanguageSelector />
              </div>
            </div>

            {/* Mobile menu button */}
            <div className="-mr-2 flex items-center md:hidden">
              <button
                onClick={handleMenuToggle}
                type="button"
                className="inline-flex items-center justify-center p-2 rounded-md text-white bg-transparent hover:transparent hover:bg-transparent"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                <svg className={`${isMenuOpen ? 'hidden' : 'block'} h-6 w-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
                <svg className={`${isMenuOpen ? 'block' : 'hidden'} h-6 w-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/* Mobile menu */}
        <div className={`${isMenuOpen ? 'block' : 'hidden'} md:hidden border-b-4 border-[color:var(--sundOr)]`}>
          <div className="h-full flex flex-col text-white gap-8 mx-auto px-4 border-t py-10 lg:px-8 sm:px-6 uppercase bg-[color:var(--sundPr)]" id='navbar nav-links'>
            <NavLink
              to={`/${i18n.language}/`}
              className={(navData) => (navData.isActive ? "active" : 'none')}
              onClick={backTop}
            >
              {t("Home")}
            </NavLink>
            <NavLink
              to={`/${i18n.language}/products/`}
              className={(navData) => (navData.isActive ? "active" : 'none')}
              onClick={backTop}
            >
              {t("Products.Title")}
            </NavLink>
            <NavLink
              to={`/${i18n.language}/services/`}
              className={(navData) => (navData.isActive ? "active" : 'none')}
              onClick={backTop}
            >
              {t("Services.Title")}
            </NavLink>
            <NavLink
              to={`/${i18n.language}/contact/`}
              className={(navData) => (navData.isActive ? "active" : 'none')}
              onClick={backTop}
            >
              {t("Contact.Title")}
            </NavLink>
            <LanguageSelector />
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
