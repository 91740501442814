// react + dependencies
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module';
// components
import PageTitle from '../components/PageTitle';
import NavLinkTop from '../components/NavLinkTop';
import ContactForm from '../components/ContactForm';

const Home = () => {
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;

  useEffect(() => {
    // Trigger Product Detail View event when the component mounts
    TagManager.dataLayer({
        dataLayer: {
            event: 'pageVisit',
            pageName: 'Homepage',
            localization: activeLanguage
        },
    });
  }, [activeLanguage, t]); // Include t in the dependency array


  return (
    <>
    <PageTitle title={t("Home")} />
    <div className='w-full' id='main'>
      <div className='relative bg-[url("../img/bg/bg-sundstrom.webp")] bg-center bg-no-repeat bg-cover'>
        <div className='absolute inset-0 bg-black opacity-75'></div> 
        <div className='w-full md:w-full m-auto min-h-[50vh] md:min-h-[70vh] relative'>
          <div className='flex flex-col md:flex-row flex-wrap items-center justify-center relative z-10 h-[50vh] md:h-[70vh] pt-20 md:pt-0 '>
            <div className='w-full md:w-4/12 p-10 md:p-0 pr-10 md:pr-4 text-center -mt-0 md:-mt-20 '>
              <h1 className='mb-3 hidden md:block text-white text-5xl pt-[80px]'>{t("Home.Title")}</h1>
              <h2 className='text-white'>{t("Home.Subtitle")}</h2>
              <hr className='my-6'/>
              <div className='flex flex-row justify-center items-center gap-4'>
                <NavLinkTop to={`/${i18n.language}/contact`}>
                  <button className='btn-primary mt-0'>
                  {t("Contact.Title")}
                  </button>
                </NavLinkTop>
                <NavLinkTop to={`/${i18n.language}/services`}>
                  <button className='btn-secondary mt-0'>
                  {t("Services.Title")}
                  </button>
                </NavLinkTop>
              </div>
            </div>
          </div>
        </div>
      </div>          

      <div className='w-full mt-10'>
        <div className="w-full md:w-10/12 m-auto">
          <div className='w-full md:w-9/12 m-auto p-8 md:p-0 mb-0 md:mb-10'>
            <p className='text-center md:text-justify leading-10'>{t("Home.MainText")}</p>
          </div>
          <div className='flex flex-col md:flex-row gap-4 p-4 md:p-0'>
              <div>
                <img src="https://ppezilla.com/img/products/sr_image_281.webp" alt="" />
              </div>
              <div>
                <img src="https://ppezilla.com/img/products/sr_production_051.webp" alt="" />
              </div>
              <div>
                <img src="https://ppezilla.com/img/products/sr_safety_073.webp" alt="" />
              </div>
              <div>
                <img src="https://ppezilla.com/img/products/sr_production_201.webp" alt="" />
              </div>
          </div>
          <div className='my-10 w-full md:w-9/12 m-auto p-8 md:p-0 mb-0 md:mb-10'>
            <p className='text-center md:text-justify leading-10'>{t("Home.BottomText")}</p>
          </div>
          <div className='w-full md:w-6/12 m-auto p-8 md:p-0'>
            <ContactForm />
          </div>
        </div>
      </div>
      
    </div>
    </>
  )
}

export default Home
